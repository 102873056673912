import * as React from 'react';
//import Link from '@mui/material/Link';
//import Table from '@mui/material/Table';
//import TableBody from '@mui/material/TableBody';
//import TableCell from '@mui/material/TableCell';
//import TableHead from '@mui/material/TableHead';
//import TableRow from '@mui/material/TableRow';
import Title from './Title';
import Paper from '@mui/material/Paper';
//import { Component } from 'react';
//import { useState, useEffect } from 'react';
//import ReactDOM from 'react-dom';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
import Button from '@mui/material/Button';
import axios from "axios";
import { useRef } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';


/*
const Syncs = () => {
    const [loading, setLoading] = React.useState(false);
    const [datas, setDatas] = React.useState([]);
    let contents = loading
        ? <Box sx={{ width: '100%' }}><LinearProgress /><p><em>&nbsp;&nbsp;Loading... </em></p></Box>
        : Sync(datas);

    return (
        <>
            {contents}
        </>
    );
}
*/

const Sync = () => {
    const [loading, setLoading] = React.useState(false);
    const [datas, setDatas] = React.useState([]);

    const inputRef = useRef(null);

    const productsSyncData = () => {
        (async () => {
            const response = await axios.get("/ab/syncproducts").then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
            setDatas(response);
            setLoading(false);
        })();
    }


    const productByIdSyncData = () => {
        (async () => {
            const url = "/ab/syncproductbyid/" + inputRef.current.value
            const response = await axios.get(url).then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
            setDatas(response);
            setLoading(false);
        })();
    }
    const customersSyncData = () => {
        (async () => {
            const response = await axios.get("/ab/synccustomers").then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
            setDatas(response);
            setLoading(false);
        })();
    }
    const subscriptionsSyncData = () => {
        (async () => {
            const response = await axios.get("/ab/syncsubscriptions").then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
            setDatas(response);
            setLoading(false);
        })();
    }
    const ordersSyncData = () => {
        (async () => {
            const response = await axios.get("/ab/syncorders").then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
            setDatas(response);
            setLoading(false);
        })();
    }
    const ordersLimitSyncData = () => { //sync 200 ostatnich zamowien
        (async () => {
            const response = await axios.get("/ab/syncorderslimit").then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
            setDatas(response);
            setLoading(false);
        })();
    }
    const eventsMS = () => {
        (async () => {
            const response = await axios.get("/utils/GetEvents").then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
            setDatas(response);
            setLoading(false);
        })();
    }

    const expiringSubscriptionsReport = () => {
        (async () => {
            const response = await axios.get("/db/GetExpiringSubscriptionsList").then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
            setDatas(response);
            setLoading(false);
        })();
    }
    const expiringSubscriptionsReportNextMonth = () => {
        (async () => {
            const response = await axios.get("/db/GetExpiringSubscriptionsListNextMonth").then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
            setDatas(response);
            setLoading(false);
        })();
    }
    /*const expiringSubscriptionsReportCalculation = () => {
        (async () => {
            const response = await axios.get("/db/GetExpiringSubscriptionsListXLS").then((response) => response.data).catch((error) => { if (error.response.status === 401) { alert("Your access is not allowed."); } });
            setDatas(response);
            setLoading(false);
        })();
    }*/

    const expiringSubscriptionsReportCalculation = async () => {
        try {
            // Pobieranie pliku XLSX w formacie binarnym
            const response = await axios.get("/db/GetExpiringSubscriptionsListXLS", {
                responseType: 'blob' // umożliwia pobranie jako Blob
            });

            // Generowanie dynamicznej nazwy pliku
            const date = new Date();
            const fileName = `zestawienie_${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}_${String(date.getHours()).padStart(2, '0')}${String(date.getMinutes()).padStart(2, '0')}${String(date.getSeconds()).padStart(2, '0')}.xlsx`;

            // Tworzenie URL dla pobranego pliku i wyzwolenie pobierania
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // ustawiamy nazwę pliku
            document.body.appendChild(link);
            link.click();

            // Sprzątanie po pobraniu
            link.parentNode.removeChild(link);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                alert("Your access is not allowed.");
            } else {
                console.error("Error downloading the report:", error);
            }
        }
    };



    const buttonStyle = { width: '300px' }; // Stała szerokość przycisków
    
 //   const cont

    return (
        <TableContainer component={Paper} sx={{ width: '70%', margin: '20px auto 0' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ width: '30%' }}>Akcja</TableCell> {/* Szerokość ustawiona na 30% */}
                        <TableCell sx={{ width: '70%' }}>Opis funkcji</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {/* Raport wygasających subskrypcji */}
                    <TableRow>
                        <TableCell>
                            <Button variant="contained"
                                sx={buttonStyle}
                                startIcon={<SyncRoundedIcon />}
                                onClick={() => { expiringSubscriptionsReportCalculation() }}
                            >
                                Kalkulacja dla wygasających subskrypcji
                            </Button>
                        </TableCell>
                        <TableCell>
                            Pobierz kalkulacje w formacie xlsx
                        </TableCell>
                    </TableRow>
                    {/* Raport wygasających subskrypcji */}
                    <TableRow>
                        <TableCell>
                            <Button variant="contained"
                                sx={buttonStyle}
                                startIcon={<SyncRoundedIcon />}
                                onClick={() => { expiringSubscriptionsReport() }}
                            >
                                Raport wygasających subskrypcji
                            </Button>
                        </TableCell>
                        <TableCell>
                            Wygeneruj raport subskrypcji, które wygasną w tym miesiącu
                        </TableCell>
                    </TableRow>
                    {/* Raport wygasających subskrypcji kolejny miesiac*/}
                    <TableRow>
                        <TableCell>
                            <Button variant="contained"
                                sx={buttonStyle}
                                startIcon={<SyncRoundedIcon />}
                                onClick={() => { expiringSubscriptionsReportNextMonth() }}
                            >
                                Raport wygasających subskrypcji
                            </Button>
                        </TableCell>
                        <TableCell>
                            Wygeneruj raport subskrypcji, które wygasną w przyszłym miesiącu
                        </TableCell>
                    </TableRow>
                    {/* Synchronizacja - Customers */}
                    <TableRow>
                        <TableCell>
                            <Button variant="contained"
                                sx={buttonStyle}
                                startIcon={<SyncRoundedIcon />}
                                onClick={() => { customersSyncData() }}
                            >
                                Synchornizacja - Customers
                            </Button>
                        </TableCell>
                        <TableCell>
                            {/* Tutaj wpisz opis funkcjonalności */}
                        </TableCell>
                    </TableRow>

                    {/* Synchronizacja - Products */}
                    <TableRow>
                        <TableCell>
                            <Button variant="contained"
                                sx={buttonStyle}
                                startIcon={<SyncRoundedIcon />}
                                onClick={() => { productsSyncData() }}
                            >
                                Synchornizacja - Products
                            </Button>
                        </TableCell>
                        <TableCell>
                            {/* Tutaj wpisz opis funkcjonalności */}
                        </TableCell>
                    </TableRow>

                    {/* Synchronizacja - Products by Id */}
                    <TableRow>
                        <TableCell>
                            <input
                                ref={inputRef}
                                type="text"
                                id="productId"
                                name="productId"
                                placeholder="Wpisz ID produktu"
                            />
                            <Button variant="contained"
                                sx={buttonStyle}
                                startIcon={<SyncRoundedIcon />}
                                onClick={() => { productByIdSyncData() }}
                            >
                                Synchornizacja - Products by Id
                            </Button>
                        </TableCell>
                        <TableCell>
                            {/* Tutaj wpisz opis funkcjonalności */}
                        </TableCell>
                    </TableRow>

                    {/* Synchronizacja - Subscriptions */}
                    <TableRow>
                        <TableCell>
                            <Button variant="contained"
                                sx={buttonStyle}
                                startIcon={<SyncRoundedIcon />}
                                onClick={() => { subscriptionsSyncData() }}
                            >
                                Synchornizacja - Subscriptions
                            </Button>
                        </TableCell>
                        <TableCell>
                            {/* Tutaj wpisz opis funkcjonalności */}
                        </TableCell>
                    </TableRow>

                    {/* Synchronizacja - Orders */}
                    <TableRow>
                        <TableCell>
                            <Button variant="contained"
                                sx={buttonStyle}
                                startIcon={<SyncRoundedIcon />}
                                onClick={() => { ordersSyncData() }}
                            >
                                Synchornizacja - Orders
                            </Button>
                        </TableCell>
                        <TableCell>
                            {/* Tutaj wpisz opis funkcjonalności */}
                        </TableCell>
                    </TableRow>

                    {/* Synchronizacja - Orders (last 200) */}
                    <TableRow>
                        <TableCell>
                            <Button variant="contained"
                                sx={buttonStyle}
                                startIcon={<SyncRoundedIcon />}
                                onClick={() => { ordersLimitSyncData() }}
                            >
                                Synchornizacja - Orders (last 200)
                            </Button>
                        </TableCell>
                        <TableCell>
                            {/* Tutaj wpisz opis funkcjonalności */}
                        </TableCell>
                    </TableRow>

                    {/* MS EVENTS */}
                    <TableRow>
                        <TableCell>
                            <Button variant="contained"
                                sx={buttonStyle}
                                startIcon={<SyncRoundedIcon />}
                                onClick={() => { eventsMS() }}
                            >
                                MS EVENTS
                            </Button>
                        </TableCell>
                        <TableCell>
                            {/* Tutaj wpisz opis funkcjonalności */}
                        </TableCell>
                    </TableRow>

                    
                </TableBody>
            </Table>
        </TableContainer>
 //   );

/*

        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <Title>Do testów - nie synchronizować!</Title>
            <Button variant="contained"
                startIcon={<SyncRoundedIcon />}
                onClick={() => { customersSyncData() }}
            >
                Synchornizacja - Customers
            </Button>
            <Button variant="contained"
                startIcon={<SyncRoundedIcon />}
                onClick={() => { productsSyncData() }}
            >
                Synchornizacja - Products
            </Button>
            <input
                ref={inputRef}
                type="text"
                id="productId"
                name="productId"
            />
            <Button variant="contained"
                startIcon={<SyncRoundedIcon />}
                onClick={() => { productByIdSyncData() }}
            >
                Synchornizacja - Products by Id
            </Button>
            <Button variant="contained"
                startIcon={<SyncRoundedIcon />}
                onClick={() => { subscriptionsSyncData() }}
            >
                Synchornizacja - Subscriptions
            </Button>
            <Button variant="contained"
                startIcon={<SyncRoundedIcon />}
                onClick={() => { ordersSyncData() }}
            >
                Synchornizacja - Orders
            </Button>
            <Button variant="contained"
                startIcon={<SyncRoundedIcon />}
                onClick={() => { ordersLimitSyncData() }}
            >
                Synchornizacja - Orders (last 200)
            </Button>
            <Button variant="contained"
                startIcon={<SyncRoundedIcon />}
                onClick={() => { eventsMS() }}
            >
                MS EVENTS
            </Button>
            <Button variant="contained"
                startIcon={<SyncRoundedIcon />}
                onClick={() => { expiringSubscriptionsReport() }}
            >
                Raport wygasających subskrypcji
            </Button>
        </Paper>*/

    );
};

export default Sync;
